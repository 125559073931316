document.addEventListener('DOMContentLoaded', function () {
	var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	document.getElementById('is-loading').style.height = h + 'px';
	document.getElementById('is-loading').style.display = 'block';
	document.getElementById('loading').style.height = h + 'px';
	document.getElementById('loading').style.display = 'block';
});

window.addEventListener('load', function () {
	setTimeout(function () {
		fadeOut('#is-loading', 600);
		fadeOut('#loading', 400);
	}, 100);
});

setTimeout(function () {
	stopload();
}, 10000);

function fadeOut(selector, duration) {
	var element = document.querySelector(selector);
	var opacity = 1;
	var interval = 10;
	var gap = interval / duration;

	var fading = setInterval(function () {
		if (opacity <= 0) {
			clearInterval(fading);
			element.style.display = 'none';
		}
		element.style.opacity = opacity;
		opacity -= gap;
	}, interval);
}

function stopload() {
	fadeOut('#is-loading', 600);
	fadeOut('#loading', 400);
}
